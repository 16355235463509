<template>
  <div class="weui-footer">
    <p class="weui-footer__links">
      <a href="/index.html">首页</a>
      <a href="/agreement.html" >用户协议</a>
      <a href="/secret.html">隐私政策</a>
<!--      <a href="/pages/weixin.html">微信点餐</a>-->
      <a href="#">店铺信息</a>
    </p>
  </div>
</template>
<script>
  export default {
    name: 'XFooter',
    data () {
      return {}
    }
  }
</script>
<style lang="less">
.weui-footer{width: 100%;float: left; }
  .weui-footer__links{width: 90%;float: left;height: 35px; line-height: 35px; margin: 5px 5%;text-align: center;}
.weui-footer__links a{margin: 0 5px;color: #333333;font-size: 12px;}
</style>
