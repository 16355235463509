<template>
<div  class="app-container">
  <div class="app-main"  style="height: 100%;">
    <slot></slot>
  </div>
  <XFooter class="footer"/>
  <XTabbar/>
</div>
</template>

<script>
  import XFooter from './components/XFooter.vue'
  import XTabbar from './components/XTabbar.vue'

  export default {
    name: 'Layout',
    components: {
      XFooter, XTabbar
    },
    props: ['bodyPaddingTop'],
    data () {
      return {
      }
    },
    computed: {
    }
  }
</script>

<style scoped>
  .app-container{width: 100%;background: #ffffff;}
  .header{
    max-width: 720px;
    margin: 0 auto;
    height: 50px;
  }
  .footer{
    width: 100%;float: left;
    max-width: 720px;
    height: 60px;
    padding: 10px 0 50px 0;
    background: #fdf2de;
  }
  .app-main {
    max-width: 720px;
    margin: 0 auto;
    min-height: calc(100vh - 150px);
  }
</style>
