import request from '@/utils/request'

// 获取商品列表
export function getList(query) {
  return request({
    url: '/goodslist.html',
    method: 'get',
    params: query
  })
}

// 获取商品列表通过分类
export function getListByid(query) {
  return request({
    url: '/goodslistByid.html',
    method: 'get',
    params: query
  })
}

// 获取农场分类列表
export function farmList(query) {
  return request({
    url: '/farmlist.html',
    method: 'get',
    params: query
  })
}

// 获取首页幻灯片
export function bannerList(query) {
  return request({
    url: '/bannerlist.html',
    method: 'get',
    params: query
  })
}

// 首页商品推荐
export function recommendList(query) {
  return request({
    url: '/recommendList.html',
    method: 'get',
    params: query
  })
}

// 获取商品列表通过分类
export function goodsTuijian(query) {
  return request({
    url: '/goodsTuijian.html',
    method: 'get',
    params: query
  })
}

// 获取城市生活馆详情信息
export function cityInfo(query) {
  return request({
    url: '/cityInfo.html',
    method: 'get',
    params: query
  })
}

// 首页专题商品推荐
export function topicList(query) {
  return request({
    url: '/topicList.html',
    method: 'get',
    params: query
  })
}

