<template>
  <Layout>
    <div class="page-home">
      <div class="slg-box">
        <img src="../../assets/slg.png">
      </div>
      <div class="search-box">
        <van-cell-group class="search">
          <van-field v-model="value" left-icon="search" placeholder="搜索商品" @click="onClick" />
        </van-cell-group>
      </div>
      <div class="gird-box">
        <van-grid square :border="false">
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=wiAeNrl5mo&reft=1698303373457&spm=seach.18570651">
                  <img src="../../assets/lsgg-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=wiAeNrl5mo&reft=1698303373457&spm=seach.18570651">
                  <img src="../../assets/lsyy-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=wiAeNrl5mo&reft=1698303373457&spm=seach.18570651">
                  <img src="../../assets/lswd-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.youzan.com/v2/showcase/usercenter?alias=el2niyet">
                  <img src="../../assets/jfsc-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=wiAeNrl5mo&reft=1698303373457&spm=seach.18570651">
                  <img src="../../assets/lsmg-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=wiAeNrl5mo&reft=1698303373457&spm=seach.18570651">
                  <img src="../../assets/lsrr-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=wiAeNrl5mo&reft=1698303373457&spm=seach.18570651">
                  <img src="../../assets/lsmq-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item>
            <template #default>
              <div class="icon-img">
                <a href="https://shop18762819.youzan.com/v2/showcase/usercenter?alias=el2niyet">
                  <img src="../../assets/yhq-icon.png" />
                </a>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </div>

      <!--商城活动-->
      <div class="yh-box">
        <van-row>
          <van-col span="24" class="djbk-list">
            <a href="https://shop18762819.m.youzan.com/wscgoods/detail/1y42kl4i7a8mznl">
              <img src="../../assets/2406_02.jpg">
            </a>
          </van-col>
        </van-row>
      </div>

      <!--当季爆款-->
      <div class="yh-box">
        <van-row>
          <van-col span="24">
            <div class="index-title">
              <img src="../../assets/2406_03.jpg" width="50%">
            </div>
          </van-col>
<!--          <van-col span="24" class="djbk-list">-->
<!--            <a href="#">-->
<!--              <img src="../../assets/2406_05.jpg">-->
<!--            </a>-->
<!--          </van-col>-->
          <van-col span="24" class="djbk-list">
            <a href="https://shop18762819.m.youzan.com/wscgoods/detail/36aaaxk6zwwwrk6">
              <img src="../../assets/2406_06.jpg">
            </a>
          </van-col>
        </van-row>
      </div>

      <!--寻味凉品-->
      <div class="yh-box">
        <van-row>
          <van-col span="24">
            <div class="index-title">
              <img src="../../assets/2406_07.jpg" width="50%">
            </div>
          </van-col>
          <van-col span="24" class="djbk-list">
            <a href="https://shop18762819.m.youzan.com/wscgoods/detail/35xy5v58sy4uzab">
              <img src="../../assets/2406_08.jpg">
            </a>
          </van-col>
          <van-col span="24" class="djbk-list">
            <a href="https://shop18762819.m.youzan.com/wscgoods/detail/1y2tvw4afg9571s">
              <img src="../../assets/2406_09.jpg">
            </a>
          </van-col>
        </van-row>
      </div>

      <!--凉山山鲜-->
      <div class="yh-box">
        <van-row>
          <van-col span="24">
            <div class="index-title">
              <img src="../../assets/2406_10.jpg" width="50%">
            </div>
          </van-col>
          <van-col span="24" class="djbk-list">
            <a href="https://shop18762819.m.youzan.com/wscgoods/detail/3erqgnk5l11y3oc">
              <img src="../../assets/2406_11.jpg">
            </a>
          </van-col>
        </van-row>
      </div>
    </div>

      <!--更多凉山味-->
<!--      <div class="yh-box">-->
<!--        <van-row>-->
<!--          <van-col span="24">-->
<!--            <img src="https://img01.yzcdn.cn/upload_files/2023/03/09/FgdU1_FHsCVS7NYKxwWEAqf6PWyY.jpg">-->
<!--            &lt;!&ndash; <img src="../../assets/lsfw.jpg">&ndash;&gt;-->
<!--          </van-col>-->
<!--        </van-row>-->
<!--      </div>-->
<!--      <div class="lsw-box">-->
<!--        <div class="father_scroll" ref="father_scroll">-->
<!--          <div class="child_scroll" ref="child_scroll">-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=2ok8ujvwihc23">-->
<!--                <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/FjrE5raRzy0DdmfHRE4LwcXtyvGv.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=365dm4tz7iwq3">-->
<!--                <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/FhYJSc6oDoy9XKDDjd1fOWsujmjf.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=367tyhv46jomj">-->
<!--                <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/FlmMmpKIaiVJoebNRwQuQU3jL7Kd.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=36a8qkcujgo9n">-->
<!--                <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/FiDe8htuhbWSljtE1HxKZtmGsGHN.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=3nkc53a8apjm3">-->
<!--                <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/Fn-VYFe-JgjCPH4sFS1YSrsXLs_U.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=2oizzi0ptie7f">-->
<!--                <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/FjVg6WJwcxiN7gATQIpjTsDRkqjc.jpg">-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="child_scroll" ref="child_scroll">-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=2ok8ujvwihc23"><img src="../../assets/taste_01.jpg"></a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=365dm4tz7iwq3"><img src="../../assets/taste_02.jpg"></a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=367tyhv46jomj"><img src="../../assets/taste_03.jpg"></a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=36a8qkcujgo9n"><img src="../../assets/taste_04.jpg"></a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=3nkc53a8apjm3"><img src="../../assets/taste_05.jpg"></a>-->
<!--            </div>-->
<!--            <div class="scroll-list">-->
<!--              <a href="https://shop18762819.m.youzan.com/v2/showcase/tag?alias=2oizzi0ptie7f"><img src="../../assets/taste_06.jpg"></a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="bottom-box">-->
<!--        <a href="#">-->
<!--          &lt;!&ndash; 牛牛福利 &ndash;&gt;-->
<!--          <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/FvBzVs3ox2Y7BTq29_owluJY0VRo.jpg">-->

<!--        </a>-->
<!--        <a href="https://shop18762819.youzan.com/v2/ump/pointsstore?kdt_id=18570651&banner_id=f.89975338~image_ad.9~1~ldOhO84l">-->
<!--          <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/FoLcnw6sch3ISf3wPCpZSkqDET2W.jpg">-->
<!--        </a>-->
<!--        <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=J0wuc7B83Z">-->
<!--          <img src="../../assets/xfj.jpg">-->
<!--        </a>-->
<!--        <a href="#">-->
<!--          <img src="../../assets/gzgzh.jpg">-->
<!--        </a>-->
<!--        <a href="https://h5.youzan.com/wscump/salesman/tutorial?kdt_id=18570651">-->
<!--          <img src="../../assets/pjgzm.jpg">-->
<!--        </a>-->
        <!--  玩味大凉山 -->
<!--        <a href="https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=ZpY6thweAa">-->
<!--          <img src="https://img01.yzcdn.cn/upload_files/2023/03/03/Fhs86u0vlaXVl5tXvhWgh3cM3u7V.jpg">-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
  </Layout>
</template>

<script>
import BScroll from 'better-scroll' // 导入better-scroll
import Layout from '../../layout/index'
import { bannerList, recommendList, topicList, goodsTuijian } from '@/api/data'
import { Button, Swipe, SwipeItem, Dialog, Grid, GridItem, Col, Row, Divider,Field, CellGroup, Tab, Tabs } from 'vant'
import 'vant/lib/index.css'
import Bscroll from 'better-scroll'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  name: 'index',
  components: {
    Layout,
    [Button.name]: Button,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Dialog.name]: Dialog,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Col.name]: Col,
    [Row.name]: Row,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  data () {
    return {
      clauseShow: true,
      bannerdata: '',
      recommenddata: '',
      topicdata: '',
      value: '',
      listData: '',
      active: 0,
      width: 0
    }
  },
  created () {
    this.$nextTick(() => { // 回调横向滚动方法
      this.personScroll()
    })
    if (this.$cookies.get('user_session')) {
      console.log('agree')
    } else {
      this.confirmDialog()
    }
    if (this.clauseShow === true) {
    }
    this.getBannerList()
    this.getRecommendList()
    this.getTopicList()
    this.getList()
  },
  mounted () {
  },
  methods: {
    onClick () {
      window.open('https://shop18762819.youzan.com/wscdeco/decorate/search-page?kdt_id=18570651',"_self")
    },
    confirmDialog () {
      Dialog.confirm({
        title: '协议与政策',
        message: '感谢您信任并使用阿斯牛牛春天工社，在您使用阿斯牛牛春天工社服务之前，请认真阅读\n' +
            '<a href="/agreement/agreement.html" >《用户协议》</a> ' + '和<a href="/secret/secret.html">《隐私政策》</a>的全部内容，以了解用户权利义务和个人信息处理规则。',
        confirmButtonText: '同意并继续',
        cancelButtonText: '不同意并退出'
      }).then(() => {
        // 不写过期时间，默认为1天过期
        this.$cookies.set('user_session', 'agreement')
      }).catch(() => {
        this.$cookies.remove('user_session')
        console.log('不同意')
        // 退出应用
        // #ifdef APP-PLUS
        plus.runtime.quit()
        // #endif
        if (uni.getSystemInfoSync().platform === 'ios') {
          plus.ios.import("UIApplication").sharedApplication().performSelector('exit')
        } else if (uni.getSystemInfoSync().platform === 'android'){
          // #ifdef APP-PLUS
          plus.runtime.quit()
          // #endif
        }
      })
    },
    getBannerList () {
      bannerList().then((response) => {
        this.bannerdata = response.data
      }).catch(err => {
        console.log(err.message)
      })
    },
    getRecommendList () {
      recommendList().then((response) => {
        this.recommenddata = response.data
      }).catch(err => {
        console.log(err.message)
      })
    },
    getTopicList() {
      topicList().then((response) => {
        this.topicdata = response.data
      }).catch(err => {
        console.log(err.message)
      })
    },
    getList () {
      goodsTuijian().then((response) => {
        this.listData = response.data
      }).catch(err => {
        // console.log(err.message)
      })
    },
    GetNum () {
      this.width = 200 * 6
      return this.width
    }, // 计算出横向滚动组件需要的总宽度
    personScroll () {
      this.$refs.child_scroll.style.width = this.GetNum + 'px'
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.father_scroll, {
            startX: 0,
            click: true,
            scrollX: true,//
            scrollY: false,
            eventPassthrough: 'vertical'
          })
        } else {
          this.scroll.refresh()
        }
      })
    } // 横向滚动
  }
}
</script>

<style lang="less" scoped>
.gird-box{width: 100%; padding-bottom: 30px;  background: #fdf2de;}
.gird-box /deep/ .van-grid-item__content--center{background: #fdf2de;}
.icon-img{width: 50%;height: 50%;}
.icon-img img{width: 100%; }
  .bottom-box{width: 100%;background: #ffffff;}
  .bottom-box img{width: 100%;}
  .tj-box{width: 100%;}
  .tj-title{width: 100%; height: 45px;}
  .tj-title img{width: 100%;}
  .tj-main{width: calc(100%); margin: 0px;}
  .tj-list{width: 100%;list-style: none;}
  .tj-list img{width: 100%}
  .yh-box{width: 100%;}
  .search-box{width: calc(100vw - 20px);height: 40px;margin:0 10px 10px 10px;}
  .search{border-radius: 10px;overflow: hidden;}
  .category_box{width: 100%;float: left;height: 380px; background: #ffffff;}
  .category_tit{width: 100%;float: left;height: 60px;line-height: 60px;}
  .cate_tit{color: #84c834; font-size: 18px;font-weight: bold;}
  .category_img{width:calc(100% - 30px);margin:0px 15px 15px 15px;float: left;border-radius: 100px;height: 100px;overflow: hidden;}
  .category_img img{width: 100%; height: 100px;}
  .category_list{width: 100%;float: left;height: 220px;}
  .van-divider::after, .van-divider::before{height: 2px;background: #84c834}
  .wrapper{width: 100%; height: 190px; overflow: hidden;
    .content{
      .item{float: left; width: 130px; height: 200px;}
    }
  }
  .item_list{width: calc(100% - 10px);padding:0 5px; float: left;background: #ffffff;}
  .item_img{width:100%; height: 120px; float: left;}
  .item_img img{width: 100%; height: 120px;}
  .item_text{width:100%; height: 38px;margin-top: 2px; float: left; line-height: 1.4em; text-align: left;font-size: 14px;
    word-wrap:break-word;
    word-break:break-all; overflow: hidden;
    text-overflow: ellipsis;
  }
  .item_text a, .item_text a:hover,.item_text a:visited,.item_text a:link{color: #333333;}
  .title a, .title a:hover,.title a:visited,.title a:link{color: #333333;}
  .item_price{width: 100%;float: left;height: 30px;line-height: 30px; font-size: 14px;text-align: left; color: #ca8b3e}
  .page-home{width: calc(100%);background: #fbf2e0;}
  .banner{width: calc(100%);height: calc(50vh);float: left; border-radius: 10px;overflow: hidden; margin:0px;}
  .ico-box{width: 100%;float: left;}
  .my-swipe .van-swipe-item {color: #fff;font-size: 20px;height: calc(50vh);text-align: center;background-color: #39a9ed;}
  .van-swipe-item img{width: 100%; height: calc(50vh);}
  .goods_list_box{width: 100%;float: left;}
  .goods_list_tit{width: 100%;float: left;height: 60px;line-height: 60px; text-align: center;font-size: 24px;display: flex;justify-content: center;align-items: center;}
  .goods_list_tit img{height: 50px;float: left;}
  .goods_list_tit span{height: 60px; line-height: 60px;margin-left: 10px;float: left;}
  .hot_title{width: calc(100% - 10px);padding: 5px;float: left;background: #ffffff;}
  .hot_title .title{width: 100%;float: left;font-size: 14px;height: 40px;  text-overflow: ellipsis; overflow: hidden;}
  .hot_title .bot{width: 100%;float: left;height: 40px;}
  .hot_price{width: 50%;height: 40px;line-height: 40px;float: left;color: #FF5A5A;text-align: center;overflow: hidden;}
  .hot_btn{width: 50%;height: 40px;line-height: 40px; float: left;text-align: center;}
  .hot_btn span{padding:5px;background: #FF4444;border-radius: 20px;color: #ffffff;font-size: 12px;}
  .hot_btn span a{color: #ffffff;}
  .list_row{width: calc(100% - 20px);margin: 10px;float: left;}
  .grid_img{width: 50%;margin: 0 25%;float: left}
  .grid_img img{width: 100%;}
  .grid_tit{width: 100%;height: 25px;line-height: 25px;font-size: 14px;text-align: center;color: #c49051;font-weight: bold;}
  /deep/ .van-grid-item__content--center{background: #ffffff;}
  .block_box{width: calc(100% - 30px);margin: 15px;float: left;}
  .block_ctgs{width: 50%;float: left;padding-right: 8px;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;}
  .block_ccwl{width: 50%;float: left;padding-left: 8px;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;}
  .block_csshg{width: 100%;float: left;padding-top: 10px;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;}
  .block_box img{width: 100%;}
  /deep/ .van-dialog__content a{color: #0000EE}
  /deep/ .van-dialog__content a:link{color: #0000EE}
  /deep/ .van-dialog__content a:visited{color: #0000EE}
  /deep/ .van-dialog__content a:hover{color: #0000EE}
  .list_box{margin-bottom: 10px;}
  .list_more{width: calc(100% - 30px);margin: 0px 15px 15px 15px;float: left; height: 40px;line-height: 40px;text-align: center;font-size: 14px;background: #ffffff;border-radius: 10px;}
  .list_more a{width: 100%;height: 100%; color: #333333;}
  .lsw-box{width: 100%;display: flex;height: 220px;background: #ffffff;}
  .lsw-title{width: 100%;}
  .father_scroll{
    position: absolute;
    height: 250px;;
    width: 100%;
    overflow: auto;
    z-index:5;
  }
  .child_scroll{
    /*width: 100%;*/
    position: absolute;
    display: flex;
  }
  .scroll-list{width: 100px;height: 225px;}
  .scroll-list img{width: 100%;}
.index-title{width: 100%;padding: 10px; height: 50px;text-align: center;display: flex;justify-content: flex-start;align-items: center}
.index-title img{width: 100%;}
.djbk-list{width: 100%;margin-bottom: 0px;padding: 0px;}
.djbk-list img{width: 100%;}
.slg-box{width: 100%;padding: 10px 0;}
.slg-box img{width: 50%;margin: 10px;}
</style>
