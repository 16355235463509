<template>
  <van-tabbar v-model="active" safe-area-inset-bottom fixed placeholder active-color="#333333" inactive-color="#333333" class="tabbar-box">
    <van-tabbar-item url="http://app.as66.com">
      <span>首页</span>
      <template #icon>
        <img :src="home" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item url='https://shop18762819.m.youzan.com/wscshop/showcase/feature?alias=wiAeNrl5mo'>
      <span>商城</span>
      <template #icon>
        <img :src="shoping" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace url="http://app.as66.com/city/city.html">
      <span>餐饮</span>
      <template #icon>
        <img :src="meal" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item url="https://shop18762819.youzan.com/wsctrade/cart?kdt_id=18570651">
      <span>购物车</span>
      <template #icon>
        <img :src="cart" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item url="https://shop18762819.youzan.com/v2/showcase/usercenter?alias=el2niyet">
      <span>我的</span>
      <template #icon>
        <img :src="user" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
  import Vue from 'vue';
  import { Tabbar, TabbarItem } from 'vant'
  import 'vant/lib/index.css'

  Vue.use(Tabbar)
  Vue.use(TabbarItem)

  export default {
    name: 'TabBar',
    components: {
    },
    data() {
      return {
        active: '',
        home: require('../../assets/home.png'),
        shoping: require('../../assets/shoping.png'),
        meal: require('../../assets/meal.png'),
        cart: require('../../assets/cart.png'),
        user: require('../../assets/user.png'),
      }
    },
    methods: {
    }
  }
</script>

<style scoped>
.tabbar-box{z-index: 9999;display: flex;position: fixed;bottom: 0;}
</style>
